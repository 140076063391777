import styled from 'styled-components'
import { lg, md, xl, primaryColor } from 'config/variables'

export const PowerofinternetStyles = styled.div`
.content-list-wrap {
    display: flex;
    justify-content: flex-end;
    .content-list {
      flex: 0 1 867px;
      ${xl(`
        flex: 0 1 821px;
     `)}
     ${lg(`
        flex: 0 1 536px;
     `)}
     ${md(`
        flex:1 1 auto; 
     `)}
      .img-frame{
        width: 100%;
        margin: 56px 0 37px;
        position: relative;
        user-select: none;
        ${lg(`
            margin: 50px 0;
        `)} 
         ${md(`
            margin: 30px 0;
        `)} 
        }
      }      
    }

.vehicleDataList {
  margin-top: 80px;
    ${xl(`
      margin-top: 74px;
    `)} 
    ${lg(`
      margin-top: 60px;
    `)} 
    ${md(`
      margin-top: 20px;
    `)} 
   .vehicleDataListRow {
       display:flex;
       height:520px;
       align-items:flex-end;
       margin:0 -15px;
       ${xl(`
           margin:0 -13px;
           height:443px;
        `)} 
        ${lg(`
           justify-content: flex-end;
           height:517px;
           flex-wrap:wrap;
        `)} 
        ${md(`
           justify-content: flex-end;
           height:724px;
           flex-wrap:wrap;
        `)} 
       .vehicleDataListColumn {
           display:flex;
           flex-wrap:wrap;
           flex:0 1 20%;
           align-items:center;
           padding:0 15px;
           ${xl(`
            padding:0 13px;
          `)} 
           ${lg(`
            padding:0 13px;
            flex:0 1 25%;
          `)} 
           ${md(`
            padding:0 13px;
            flex:0 1 50%;
          `)} 
           &:nth-child(even) {
              align-self:flex-start;
              .vehicleDataListBox {
                margin-bottom:30px;
                margin-top:0;
                ${md(`
                  margin-bottom:20px;
                `)} 
              }
            }
            &:first-child {
              ${lg(`
                padding-top:30px;
              `)} 
               ${md(`
                padding-top:40px;
              `)} 
            } 
            &:nth-child(2) {
              ${lg(`
                padding-top:10px;
              `)} 
              ${md(`
                padding-top:0px;
              `)} 

            }
            &:nth-child(3) {
              ${lg(`
                padding-top:30px;
              `)} 
              ${md(`
                padding-top:0px;
              `)} 
            }   
            &:nth-child(4) {
              ${md(`
                margin-top:-40px;
              `)} 
            }   
            &:last-child {
              align-self:center;
              ${lg(`
                margin-top:-30px;
              `)}
              ${md(`
                margin-top:-40px;
              `)}
             
              .vehicleDataListBox { 
                margin-top:0px;
              }
            }    
        .vehicleDataListBox {
            background-image: linear-gradient(135deg, rgba(222, 255, 252, 0.7), rgba(207, 254, 250, 0.7));
            width:100%;
            height:200px;
            position:relative;   
            padding:20px 15px 20px 20px;
            margin-top:30px;
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            ${xl(`
              height:167px;
              padding:10px 15px 10px 20px;
            `)} 
             ${lg(`
              height:153px;
              padding:10px 15px 10px 20px;
            `)} 
             ${md(`
              height:128px;
              padding:10px 15px 10px 20px;
              margin-top:20px;
            `)} 
           .vehicleDataListBoxBg {
                position:absolute;
                content:"";
                top:-8px;
                right:-8px;
                width:100%;
                height:100%;
                z-index:-1;
                background:#c2f4f0;
           } 
           &.powder-blue {
            background-image: linear-gradient(225deg, rgba(218, 226, 240, 0.68), rgba(205, 213, 228, 0.68));
            .vehicleDataListBoxBg {
              background:#cdd3e2;
            }
           }
           &.light-peach {
            background-image: linear-gradient(225deg, rgba(255, 240, 225, 0.7), rgba(255, 238, 219, 0.7));
            .vehicleDataListBoxBg {
              background:#ffe1c1;
            }
           }
           &.pale-mauve-two {
            background-image: linear-gradient(225deg, rgba(255, 229, 250, 0.7), rgba(255, 232, 251, 0.7));
            .vehicleDataListBoxBg {
              background:#ffdcf9;
            }
           }
           &.light-sky-blue {
              background-image: linear-gradient(225deg, rgba(226, 247, 255, 0.7), rgba(225, 246, 255, 0.7));
              .vehicleDataListBoxBg {
                background:#cceffd;
              }
            } 
            &.light-peach-five {
              background-image: linear-gradient(225deg, rgba(242, 242, 242, 0.7), rgba(245, 245, 245, 0.7));
              .vehicleDataListBoxBg {
                background:#e2dede;
              }
            }
            &.very-light-pink-five {
              background-image: linear-gradient(225deg, rgba(255, 244, 240, 0.7), rgba(250, 232, 226, 0.7));
              .vehicleDataListBoxBg {
                background:#ffe7de;
              }
            }
            &.soft-pink-two {
              background-image: linear-gradient(225deg, rgba(255, 220, 222, 0.7), rgba(252, 212, 215, 0.7));
              .vehicleDataListBoxBg {
                background:#f9bbc0;
              }
            }
            &.pastel-blue {
              background-image: linear-gradient(225deg, rgba(212, 220, 255, 0.7), rgba(231, 236, 255, 0.7));
              .vehicleDataListBoxBg {
                background:#b1bef3;
              }
            }
           img {
             margin-bottom:10px;
             ${xl(`
              margin-bottom:5px;
            `)}
            ${lg(`
              margin-bottom:5px;
              max-width:40px;
            `)}
           }
           p{
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            margin:0px;
            color:${primaryColor};
            ${xl(`
              font-size: 14px;
            `)}
             ${lg(`
                font-size: 13px;
                line-height:18px;
            `)}
            ${md(`
                font-size: 11px;
                line-height:15px;
            `)}
           }
        }  
        
       }
   } 
}
  
`
