import React from 'react'
import { images } from 'config/images'

export const trygProps = {
  bannerProps: {
    heading: 'Tryg i Bil',
    bannerPara: (
      <>
        Scandinavia's second largest general insurer brings transparency to
        insurance claims with telematics
      </>
    ),
    BannerAlt: 'Tryg i Bil Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        Tryg is the second largest non-life insurance company in Scandinavia.
        They wanted to bring telematics insights to their insurance business so
        that they can digitally transform how their business is run. <br />{' '}
        <br />
        Today, insurance companies are blind to auto-insurance claims. With
        telematics, they know there was an accident or there was a bodily injury
        event in real-time so they can be proactive. Drivers can also benefit
        from the feedback on their driving behaviors that these devices give and
        take more control over their own premiums.
      </>
    ),
  },
  PowerofinternetProps: {
    heading:
      'Harness the power of Internet of Things to get insights into fleets',
    topPara:
      'We built a fleet tracking solution for fleet operators and drivers insights into vehicle and driving behaviours. Insurers also got deeper insights into the claims and vehicle health while customers can file claims in 3 easy steps. We harnessed the power of IoT, cloud, data processing, and web/mobile apps to build an end-to-end vehicle tracking and fleet management solutions caters to business needs of fleet operators (Trucks, Bus, Taxis, School Buses etc.).',
    bottomPara:
      'The vehicles are networked entity that can communicate things like real time location, vehicle status, tire pressure, ignition, AC Status, number of passengers etc. using the multiple sensors. The enormous amount of vehicle data, customer and driver records thus helps in :',
    vehicleDataLists: [
      [
        {
          Img: images.truckIcon,
          para: 'Real-time fleet management and tracking.',
        },
        {
          Img: images.dollarIcon,
          para: 'Reduced operational costs including fuel costs.',
          className: 'powder-blue',
        },
      ],
      [
        {
          Img: images.carIcon,
          para:
            'Proactive vehicle diagnosis and health insights to prevent expensive damages.',
          className: 'light-peach',
        },
        {
          Img: images.driverSafetyIcon,
          para: 'Increases driver safety using high risk behavior models. ',
          className: 'pale-mauve-two',
        },
      ],
      [
        {
          Img: images.accidentsIcon,
          para: 'Timely and efficient crisis management during accidents.',
          className: 'light-sky-blue',
        },
        {
          Img: images.productivityIcon,
          para: 'Decreased idle hours and improved productivity of drivers.',
          className: 'light-peach-five',
        },
      ],
      [
        {
          Img: images.utilizationIcon,
          para: 'Effective utilization of the vehicles.',
          className: 'very-light-pink-five',
        },
        {
          Img: images.analyticsIcon,
          para:
            'Deeper insights into vehicle and driver data with reports and analytics.',
          className: 'soft-pink-two',
        },
      ],
      [
        {
          Img: images.insuranceIcon,
          para: 'Insurance and claim generation.',
          className: 'pastel-blue',
        },
      ],
    ],
  },

  casereSultsecProps: {
    heading: 'Result',
    maincontent: (
      <>
        We worked with the digital transformation team at Tryg and Saga Systems
        build a hardware + software solution that is successfully being deployed
        and used by Tryg’s customers.
        <br /> <br />
        Feedback supports our assumption that telematics increases safety
        benefits and telematics insurance has cut accident risk greatly.
      </>
    ),
    videoURL: images.trygVideo,
  },
  MobileappdrivercontrolProps: {
    Title: 'Design',
    subTitle: <>Mobile app to put driver in control of their trips</>,
    para: (
      <>
        At the forefront of the Tryg’s insurance telematics solution is the
        fleet management for drivers and fleet operators. We integrated mobile
        apps and cloud backend with an OBD-II based interface that relays real
        time information to Fleet managers. The mobile app puts drivers in focus
        and allows them to take full control of their driving. It helps reduce
        cost, save time, and get right tax benefits for employees and companies.
        Fleet managers can manage and track fleet with real-time location based
        reports.
      </>
    ),
    mobileAppScreenDetails: [
      {
        screenDetails:
          'Get an overview of driver’s driving score, vehicle health score, and past trips.',
      },
      {
        screenDetails:
          'Send monthly trips documentation right from your phone.',
      },
      {
        screenDetails: 'Claim insurance right from phone.',
      },
      {
        screenDetails:
          'Capture your drives automatically. The app runs in the background tracking your miles and creating a comprehensive record of your drives.',
      },
      {
        screenDetails:
          'Create rules to automatically tag trips as business or private. ',
      },
      {
        screenDetails: 'Check Trip history on the map.',
      },
      {
        screenDetails:
          'Get a notification if someone tries to move your car or disconnect the device',
      },
      {
        screenDetails:
          'Proactive vehicle diagnosis and health insights to prevent expensive damages.',
      },

      {
        screenDetails:
          'Swipe left or right to easily mark trips as business or personal to reimburse mileage or claim every mileage tax deduction.',
      },
    ],
  },
  Fleetarchitectureprops: {
    heading:
      'Connecting fleet with IoT cloud for Analytics on the data to garner insights',
    LeftDetailsPara: (
      <>
        {' '}
        We believe data is useless if not used properly. Tryg’s telematics
        solution turns data into insights by creating statistics and presents it
        in content rich and interactive format. <br /> <br />
        Monitoring the condition of the bus and maintenance planning is a
        critical part of the fleet operation to ensure that the services run
        smoothly. <br /> <br />
        The solution tracks different vehicle parameters in real time and
        analyzes these data to find new state problems. <br /> <br />
        This allows the fleet managers to schedule maintenance in advance. It
        also includes processes that allow you to combine manual tasks with
        state analysis data to give you full control of vehicle maintenance.{' '}
        <br /> <br />
        Today’s car is more than a vehicle. It contains computing power
        equivalent to 20 personal computers, has about 100 million lines of
        programming code and processes up to 25 gigabyte of data per hour.{' '}
        <br /> <br />
        To collect and process huge amounts of IoT data stream requires fool
        proof architecture to leave no room for errors or security flaws.{' '}
      </>
    ),
    RightDetailsPara: (
      <>The biggest challenges when developing such a solution are: </>
    ),

    FleetarchitectureList: [
      'Receive, ingest, and store data in real time from vehicles sensors and devices on a large scale.',
      'Analyze the data to understand vehicle location, and other information emitted through different types of sensors data related to vehicle speed, engine coolant temperature & RPM, Trip Mileage/Fuel Consumption, hard acceleration/brake, engine idle time etc.',
      'Leverage the collected data to derive real-time & predictive insights for other downstream processing (For example, in accident scenarios, insurance agencies may be interested to know what happened during an accident etc.)',
      'The Telematic IoT platform should be built with the purpose of being able to talk with third party systems as well as export and import data.',
    ],
    LeftBottomDetailsPara: (
      <>
        The architecture we recommended for IoT solutions is cloud native,
        microservice, and serverless based. The IoT subsystems were built as
        discrete services that are independently deployable, and able to scale
        independently. These attributes enable greater scale, more flexibility
        in updating individual subsystems, and provide the flexibility to choose
        appropriate technology on a per subsystem basis.
        <br /> <br />
        It is critical to have the ability to monitor individual subsystems as
        well as the IoT application as a whole. <br /> <br />
        From the telemetry upload, the solution uses Azure Stream Analytics to
        clean the data, storing a copy of every message in long-term Azure Blob
        storage (cold storage). We use Stream Analytics to perform multiple jobs
        related to determining which customer device is sending the data.
        <br /> <br />
        From there, the data flows into a clean event hub constructed from Azure
        Event Hubs. Once in the clean event hub, the solution runs a Stream
        Analytics job to push messages to an Azure message queue, where the
        solution provides hot, or real-time, message analytics.
      </>
    ),
    RightBottomDetailsPara:
      'At the core an IoT application consists of the following subsystems:',
    FleetarchitectureBottomList: [
      'Devices (and/or on premise edge gateways) that have the ability to securely register with the cloud, and connectivity options for sending and receiving data with the cloud',
      'A cloud gateway service, or hub, to securely accept that data and provide device management capabilities',
      'Stream processors that consume that data, integrate with business processes, and place the data into storage',
      'A user interface to visualize telemetry data and facilitate device management. ',
    ],
    RightBottomListPara:
      'The on-vehicle sensors pass data to the Azure IoT Edge. The field gateway posts the data to the Azure IoT Hub.',
    mainImgcaption:
      'Tryg i bill backend architecture with various Azure services',
  },
  TreemappingProps: {
    title: 'Web portal for managing the fleet and easier decision making',
    maincontent: (
      <>
        Just how mobile solution is a great tool for drivers, the web portal is
        the center console for managers to be on top of their fleet. Web
        solution that gives the person responsible for corporate cars a complete
        overview of driving data and where the cars are located. They can use
        real-time and historic vehicle location and performance data to
        determine where changes can be implemented to improve customer
        satisfaction, reduce operating costs, and increase driver safety. The
        driver profiling is an integral part of the system and helps optimize
        performance. <br />
        <br />
        Fleet managers can define a secure work radius. if the vehicle goes out
        of this radius, an alert is sent out to the fleet managers and local
        authorities. Buses and trucks health and planning maintenance is
        critical part of the fleet operation to assure the services run
        smoothly. The solution tracks different vehicle parameters in realtime
        and analyzes this data to find emerging health issues. This allows fleet
        managers to plan for any maintenance in advance. It also includes
        processes that allow combining manual tasks with the health analysis
        data to give full control over vehicle maintenance.
      </>
    ),
  },
  CasePowerToolsProps: {
    mainTitle: 'Web portal for managing the fleet and easier decision making',
    CasePowerToolsDetails: [
      {
        SubTitle: '',
        para:
          'Just how mobile solution is a great tool for drivers, the web portal is the center console for managers to be on top of their fleet. Web solution that gives the person responsible for corporate cars a complete overview of driving data and where the cars are located. They can use real-time and historic vehicle location and performance data to determine where changes can be implemented to improve customer satisfaction, reduce operating costs, and increase driver safety. The driver profiling is an integral part of the system and helps optimize performance.',
      },
      {
        SubTitle: '',
        para:
          'Fleet managers can define a secure work radius. if the vehicle goes out of this radius, an alert is sent out to the fleet managers and local authorities. Buses and trucks health and planning maintenance is critical part of the fleet operation to assure the services run smoothly. The solution tracks different vehicle parameters in realtime and analyzes this data to find emerging health issues. This allows fleet managers to plan for any maintenance in advance. It also includes processes that allow combining manual tasks with the health analysis data to give full control over vehicle maintenance.',
      },
      {
        SubTitle: 'Detailed Maps',
        para:
          'Fleet tracking software provides high-resolution maps with smart clustering and detail on demand provide a simple yet powerful tool to view the near real-time activity and current status of all your vehicles, workers and mobile equipment no matter where they are.',
      },
      {
        SubTitle: 'Easy-to-use dashboards',
        para:
          'See important trends by team, department or across the entire fleet. Easily review your progress towards achieving and maintaining KPIs or budgets with up-to-the-minute fleet analytics provided by our fleet solution.',
      },
    ],
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'Decora',
        para: 'Meaningful innovation to drive Interior Designing virtually',
        className: 'robin-egg-blue',
        link: 'case-studies/decora-systems/',
      },
      {
        title: 'Fitcom',
        para: 'Custom app solutions for health, wellness & fitness industries',
        className: 'bluegrey',
        link: 'case-studies/fitcom/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
