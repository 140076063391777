import React from 'react'
import { PowerofinternetStyles } from './Powerofinternet.styles'
import Image from 'components/ImgOptimized'
import Casequotesec from 'components/Casequotesec/Casequotesec'

function Powerofinternet(props) {
  const { PowerofinternetProps } = props.Powerofinternetdata
  const caseQuoteProp = PowerofinternetProps.caseQuoteProps
  const {
    data: {
      bespokeSimage: {
        childImageSharp: { fluid: bespokeSimage },
      },
    },
  } = props

  return (
    <PowerofinternetStyles className="powerofinternetSec">
      <div className="container">
        <h2>{PowerofinternetProps.heading}</h2>
        <div className="content-list-wrap">
          <div className="content-list">
            <p>{PowerofinternetProps.topPara}</p>
            <div className="img-frame">
              <figure>
                <Image
                  fluid={bespokeSimage}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 451,
                  }}
                />
              </figure>
            </div>
            <p>{PowerofinternetProps.bottomPara}</p>
          </div>
        </div>
        <div className="vehicleDataList">
          <div className="vehicleDataListRow">
            {PowerofinternetProps.vehicleDataLists.map((vehicleDataList, i) => {
              return (
                <div key={i} className="vehicleDataListColumn">
                  {vehicleDataList.map((vehicleDataListrow, i) => {
                    return (
                      <div
                        key={`${i}${i}`}
                        className={`vehicleDataListBox ${vehicleDataListrow.className}`}
                      >
                        <div className="vehicleDataListBoxBg"></div>
                        <img src={vehicleDataListrow.Img} alt="Icon" />
                        <p>{vehicleDataListrow.para}</p>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </PowerofinternetStyles>
  )
}

export default Powerofinternet
