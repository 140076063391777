import React, { memo } from 'react'
import styled from 'styled-components'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import { trygProps } from 'components/Props/tryg'
import Casebanner from 'components/Casebanner/Casebanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Powerofinternet from 'components/Powerofinternet/Powerofinternet'
import Mobileappdrivercontrol from 'components/Mobileappdrivercontrol/Mobileappdrivercontrol'
import { xl, lg, md } from 'config/variables'
import Fleetarchitecture from 'components/Fleetarchitecture/Fleetarchitecture'
import CasePowerTools from 'components/CasePowerTools/CasePowerTools'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import Caseresultsec from 'components/Caseresultsec/Caseresultsec'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'

export const TrygPageStyle = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #6a6a6a;
          }
        }
      }
    }
  }
  .ResultfullimgSec {
    figure {
      margin: 70px 0 0;
    }
  }
  . {
    margin-top: 50px;
    ${xl(`
     margin-top: 30px;
    `)}
    ${lg(`
      margin-top: 20px;
    `)}
  }

  .casepower-section {
    .powerContentRow {
      align-items: center;
      padding-bottom: 50px;
      ${lg(`
        padding-bottom:40px;
      `)}
      ${md(`
        padding-bottom:30px;
      `)}
      &:last-child {
        padding-bottom: 0px;
      }
    }
  }
  .Caseresultsec {
    padding-bottom: 0px;
    margin-bottom: 100px;
    ${lg(`
         padding-bottom:0px;
         margin-bottom:90px;
      `)}
    ${md(`
         margin-bottom:40px;
      `)}
    &::after {
      display: none;
    }
  }
  .MobileappdrivercontrolSec {
    padding-bottom: 100px;
    margin-bottom: 128px;
    position: relative;
     ${xl(` 
      margin-bottom: 100px;
    `)}
    ${lg(` 
      padding-bottom: 80px; 
       margin-bottom: 90px;
    `)}
    ${md(` 
      padding-bottom: 40px; 
       margin-bottom: 40px;
    `)}
  &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 790px;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f8f9ff);
      z-index: -1;
    }
    .mobileAppsContent {
      .mobileAppsContentLeft {
        .mobileAppsTitle {
          h2 {
            br {
              display: none;
            }
          }
        }
      }
    }
    .casequote-section {
      margin-top: 44px;
      ${lg(`
         margin-top:30px;
      `)}
    }
  }
  .powerofinternetSec {
    padding-bottom: 100px;
    margin-bottom: 128px;
    position: relative;
     ${xl(` 
      margin-bottom: 100px;
    `)}
    ${lg(` 
      padding-bottom: 80px; 
       margin-bottom: 90px;
    `)}
    ${md(` 
      padding-bottom: 40px; 
       margin-bottom: 40px;
    `)}
  &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 790px;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f8f9ff);
      z-index: -1;
    }
  }
 
`
const Tryg = memo(props => {
  const { data } = props
  const { location } = props
  let item = [
    props.data.webPortalScreen.childImageSharp.fluid,
    props.data.fleetManagersScreen.childImageSharp.fluid,
    props.data.mobileEvCharging.childImageSharp.fluid,
    props.data.chargingPlatform.childImageSharp.fluid,
  ]
  let appScreen = [
    props.data.caseappScreen1.childImageSharp.fluid,
    props.data.caseappScreen2.childImageSharp.fluid,
    props.data.caseappScreen3.childImageSharp.fluid,
    props.data.caseappScreen4.childImageSharp.fluid,
    props.data.caseappScreen5.childImageSharp.fluid,
    props.data.caseappScreen6.childImageSharp.fluid,
    props.data.caseappScreen7.childImageSharp.fluid,
    props.data.caseappScreen8.childImageSharp.fluid,
    props.data.caseappScreen9.childImageSharp.fluid,
  ]
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Tryg"
        description="Simform brought the power of telematics, IoT, cloud, data processing and web/mobile apps for Tryg in building end-to-end vehicle tracking and fleet management solution."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/case-studies/tryg/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/case-studies/tryg/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <TrygPageStyle>
        <Casebanner bannerdata={trygProps} {...props} showFullimages={true} />
        <Caseintroduction
          caseintrodata={trygProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Powerofinternet Powerofinternetdata={trygProps} {...props} />

          <Caseresultsec
            caseresultsecdata={trygProps}
            {...props}
            showFullimages
          />
        </div>
        {showContent && (
          <>
            <Mobileappdrivercontrol
              Mobileappdrivercontroldata={trygProps}
              {...props}
              items={appScreen}
            />
            <Fleetarchitecture
              Fleetarchitecturedata={trygProps}
              {...props}
              showFullimages
            />
            <CasePowerTools
              CasePowerToolsdata={trygProps.CasePowerToolsProps}
              items={item}
            />
            <Relatedcaselist relatedcaselistdata={trygProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={trygProps} />
          </>
        )}
      </TrygPageStyle>
    </Layout>
  )
})
export default Tryg

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/tryg-banner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    bespokeSimage: file(
      relativePath: { regex: "/power-of-internet-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 925) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    ArchitectureImages: file(
      relativePath: { regex: "/fleet-architecture-diagram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    treemappingthumb: file(
      relativePath: { regex: "/backhosted-architech@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 867) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mobileEvCharging: file(relativePath: { regex: "/detailed-map@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    chargingPlatform: file(
      relativePath: { regex: "/detailed-dashboards@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webPortalScreen: file(
      relativePath: { regex: "/web-portal-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fleetManagersScreen: file(
      relativePath: { regex: "/fleet-managers-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/decora-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/fitcom-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseappScreen1: file(relativePath: { regex: "/caseappScreen1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseappScreen2: file(relativePath: { regex: "/caseappScreen2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseappScreen3: file(relativePath: { regex: "/caseappScreen3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseappScreen4: file(relativePath: { regex: "/caseappScreen4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseappScreen5: file(relativePath: { regex: "/caseappScreen5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseappScreen6: file(relativePath: { regex: "/caseappScreen6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseappScreen7: file(relativePath: { regex: "/caseappScreen7@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseappScreen8: file(relativePath: { regex: "/caseappScreen8@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseappScreen9: file(relativePath: { regex: "/caseappScreen9@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen1: file(relativePath: { regex: "/swifts1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    caseFullimages: file(relativePath: { regex: "/insuranceDetails@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 866) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    caseScreen2: file(relativePath: { regex: "/swifts2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen3: file(relativePath: { regex: "/swifts3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen4: file(relativePath: { regex: "/swifts4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen5: file(relativePath: { regex: "/swifts5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen6: file(relativePath: { regex: "/swifts6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen7: file(relativePath: { regex: "/swifts7@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen8: file(relativePath: { regex: "/swifts8@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen9: file(relativePath: { regex: "/swifts9@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
